import { FooterQuery } from '@labs/schema';
import { graphql, useStaticQuery } from 'gatsby';

export function useFooter() {
  return useStaticQuery<FooterQuery>(graphql`
    query Footer {
      ...FooterQuery
    }
  `);
}
