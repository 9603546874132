function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { selectAll } from "hast-util-select";
import { toString } from "hast-util-to-string";
var arrow = [
    "list-none block text-turquoise-500 hover:text-turquoise-500 absolute top-0 left-0 transition",
    "pl-[17px] lg:pl-[27px] before:content-[''] before:block before:absolute before:top- before:top-[-0.6em] before:-left-[28px] lg:before:-left-[46px] before:w-[17px] before:h-[9px] lg:before:w-[23px] lg:before:h-[12px] before:bg-[url('/images/arrow.svg')] before:bg-center before:bg-cover"
];
var list = "grid grid-cols-1 gap-x-12 lg:grid-cols-2";
export var listBullet = function() {
    return function() {
        return function(tree) {
            selectAll("ul", tree).forEach(function(node) {
                // If all list items are below 60 characters, we can put them in columns.
                var inColumns = node.children.filter(function(child) {
                    return toString(child).length > 60;
                }).length === 0;
                if (inColumns) {
                    node.properties = {
                        class: list
                    };
                }
            });
            selectAll("ul > li", tree).forEach(function(node) {
                node.children = [
                    {
                        type: "element",
                        tagName: "span",
                        properties: {
                            class: arrow
                        },
                        children: []
                    }
                ].concat(_toConsumableArray(node.children));
            });
        };
    };
};
