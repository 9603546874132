import { PageInfoFragment } from '@labs/schema';
import { Footer, Header, HeroSection, Layout } from '@labs/ux';
import { WrapPageElementNodeArgs } from 'gatsby';
import React, { PropsWithChildren } from 'react';

import { useFooter } from '../hooks/useFooter';
import { useHeader } from '../hooks/useHeader';
import { InitWrapper } from '../utils/init';

export default function PageLayout({
  children,
  pageContext,
}: PropsWithChildren<WrapPageElementNodeArgs<any, PageInfoFragment>['props']>) {
  const header = useHeader();
  return (
    <InitWrapper locale={pageContext.locale}>
      <Layout
        pointer={true}
        headerProps={header}
        header={
          pageContext.heroImage ? (
            <HeroSection {...header} currentPage={pageContext} />
          ) : (
            <Header {...header} currentPage={pageContext} />
          )
        }
        footer={<Footer {...useFooter()} currentPage={pageContext} />}
      >
        {children}
      </Layout>
    </InitWrapper>
  );
}
