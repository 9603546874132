import { HeaderQuery } from '@labs/schema';
import { graphql, useStaticQuery } from 'gatsby';

export function useHeader() {
  return useStaticQuery<HeaderQuery>(graphql`
    query Header {
      ...HeaderQuery
    }
  `);
}
