import { Locale } from '@labs/schema';
import { initializeIntl } from '@labs/ux';
import React, { ComponentProps, PropsWithChildren } from 'react';

import translations from '../../data/translations.json';
import { IntlWrapper } from './intl';

export function InitWrapper({
  children,
  ...props
}: PropsWithChildren<
  Omit<ComponentProps<typeof IntlWrapper>, 'messages' | 'locale'> & {
    locale: Locale;
  }
>) {
  initializeIntl({ ...props, messages: translations[props.locale] });
  return (
    <IntlWrapper {...props} messages={translations[props.locale]}>
      {children}
    </IntlWrapper>
  );
}
