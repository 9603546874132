import './styles.css';

import { initializeIntl } from '@labs/ux';
import { GatsbyBrowser } from 'gatsby';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  // This is necessary so '@labs/ux' imports are available in the browser.
  // Why? I don't know. Its somewhere hidden in server components and might
  // change in the future.
  // TODO: Get rid of this when gatsby loads intl correctly.
  initializeIntl.toString();

  if (window.location.hash.match(/token/)) {
    window.location.pathname = '/admin/';
  }
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition duration from `Layout.tsx` * 1000 to get time in ms
  // * 2 for exit + enter animation
  const TRANSITION_DELAY = 0.3 * 1000 * 2;
  // if it's a "normal" route
  // @ts-ignore
  if (location.action === 'PUSH') {
    window.setTimeout(
      () =>
        window.scrollTo({
          top: 0,
          // behavior: 'smooth',
        }),
      TRANSITION_DELAY,
    );
  }
  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];
    window.setTimeout(
      () =>
        window.scrollTo({
          top: savedPosition[0],
          left: savedPosition[1],
          // behavior: 'smooth',
        }),
      TRANSITION_DELAY,
    );
  }
  return false;
};
